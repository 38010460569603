import {cssVal, getDataByCodePostal, newInput, texteForSiege} from "@/data/helpers";
import {def} from "@/data/dictionnaire";


export const changementNomPersonneMajeur = {
    title: "Changement de nom personne majeure",
    texte: "<strong>{{demandeurGroup.civilite}} {{demandeurGroup.prenom}} {{demandeurGroup.nom}}</strong> né le {{demandeurGroup.dateNaissance}} à {{demandeurGroup.lieuNaissance}} ({{demandeurGroup.departementStr}}), {{lieuResidenceGroup}} dépose auprès du Garde des Sceaux une requête afin de substituer à son nom patronymique celui de <strong>{{ModificationGroup.nouveauPatronyme}}</strong>.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "lieuResidenceGroup":
                tmpText = texteForSiege(varText, 'lieuResidenceGroup');
                break;
            case "demandeurGroup.civilite":
                if(varText['demandeurGroup.civilite'] === 'homme') {
                    tmpText = cssVal('M.');
                } else if(varText['demandeurGroup.civilite'] === 'femme'){
                    tmpText = cssVal('Mme');
                }
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        lieuResidenceGroup: [{}]
    },
    schema: [
        {
            //0
            name: "demandeurGroup",
            label: "Demandeur",
            type: "group",
            value: [{}],
            children: [
                newInput(def.civilite, '_required'),
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissance, '_required'),
                newInput(def.departementStr, '_required'),
            ]
        },
        {
            //1
            name: "lieuResidenceGroup",
            label: "Lieu de Résidence",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if (Object.keys(data).length === 0) {
                                return false;
                            }

                            changementNomPersonneMajeur.schema[1].children[3].options = data.options;
                            changementNomPersonneMajeur.model.lieuResidenceGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //2
            name: "ModificationGroup",
            label: "modification",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nouveauPatronyme, '_required')
            ]
        }
    ]
}